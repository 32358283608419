/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Future of Boating: Hydrogen-Powered Boat Debuts in Miami"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "The first boat powered by the \"fuel of the future\" has made its debut in Miami. This cutting-edge boat design was unveiled at the Miami International Boat Show and is powered by a Yamaha engine built to run on hydrogen, which is often considered the fuel of the future. The boat is the world's first recreational boat to be powered by hydrogen."), "\n", React.createElement(_components.h2, null, "Testing and Potential Impact"), "\n", React.createElement(_components.p, null, "According to the Miami Herald, the boat will be tested to evaluate its performance and efficiency. The use of hydrogen as a fuel source for boats has the potential to reduce carbon emissions and contribute to a more sustainable boating industry."), "\n", React.createElement(_components.p, null, "For more information, you can refer to the following sources:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.miamiherald.com/news/local/environment/climate-change/article285553607.html"
  }, "Miami Herald - First boat powered by 'fuel of the future' debuts in Miami")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.miamiherald.com/news/local/environment/climate-change/article285571527.html"
  }, "Miami Herald - Hydrogen boat makes its world debut at the Miami International Boat Show")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.msn.com/en-xl/news/other/the-fuel-of-the-future-for-boating-launched-at-the-miami-international-boat-show/vi-BB1ip4fn"
  }, "MSN - The fuel of the future for boating launched at the Miami International Boat Show")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
